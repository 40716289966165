import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

const Home = lazy(() => import("./pages/Home"));
const Upgrades = lazy(() => import("./pages/Upgrades"));
const Dedicated = lazy(() => import("./pages/Dedicated"));
const Packages = lazy(() => import("./pages/Packages"));
const Prime = lazy(() => import("./pages/Prime"));
const ThankYou = lazy(() => import("./pages/ThankYou"));

function App() {
  return (
    <Suspense>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/cosmic-clavstore" element={<Home />} />
        <Route exact path="/upgrade" element={<Upgrades />} />
        <Route exact path="/dedicated" element={<Dedicated />} />
        <Route exact path="/package" element={<Packages />} />
        <Route exact path="/prime" element={<Prime />} />
        <Route exact path="/cosmic-thankyou" element={<ThankYou />} />
      </Routes>
    </Suspense>
  );
}

export default App;
